import { ScanOutlined } from '@ant-design/icons'
import { Button, Checkbox, Empty, Form, Input, InputNumber, Modal, Spin } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { filter, get, isEmpty, map, remove, sortBy, uniq } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

import ScanBarcode from '@Modules/App/Input/ScanBarcode'
import LeavePageBlocker from '@Modules/Document/screens/Importing/components/LeavePageBlocker'
import apiProduct from '@Modules/Product/services/api'
import api from '@Modules/Warehouse/services/api'

import processResponseError from '@System/api/processResponseError'
import { events } from '@System/events'
import useGeneratorKey, { IdempotencyStorage } from '@System/hooks/useGeneratorKey'
import { t } from '@System/i18n'
import notification from '@System/notification'
import { generatorHashKey } from '@System/support/helpers'

import ModalBody from './ModalBody'
import moment from 'moment'

const TableCustomize = ({ input, warehouseAreaList, childrenReset }) => {
    const { xs } = useBreakpoint()
    const [form] = Form.useForm()
    const { warehouse_id, warehouse_area_id } = input
    const { uniqueKey, generatorKey } = useGeneratorKey()
    const [dirty, setDirty] = useState(false)

    const [loading, setLoading] = useState(false)

    const [loadingButton, setLoadingButton] = useState(false)

    const [pagination, setPagination] = useState({})

    const [params, setParams] = useState({})

    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    const [indeterminate, setIndeterminate] = useState(false)
    const [checkAll, setCheckAll] = useState(false)

    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        if (childrenReset === 'RESET') {
            setDataSource([])
        }
    }, [childrenReset])

    const getProducts = useCallback(() => {
        const { warehouse_id, warehouse_area_id } = input

        if (!!warehouse_area_id && !!warehouse_id) {
            setLoading(true)
            apiProduct
                .getStocks({ ...input, ...params })
                .then(res => {
                    form.resetFields()
                    setDataSource(get(res, 'data.stocks'))
                    setPagination(get(res, 'data.pagination'))
                })
                .catch(err => {
                    processResponseError(err)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [form, input, params])

    useEffect(() => {
        getProducts()
    }, [getProducts])

    const onCheckAllChange = e => {
        setIndeterminate(false)
        setCheckAll(e.target.checked)
        setSelectedRowKeys(e.target.checked ? uniq(filter(map(dataSource, 'stock.id'), item => !!item)) : [])
    }

    const onChangeCheck = (skuId, e) => {
        const newSelectedRowKeys = [...selectedRowKeys]
        e.target.checked ? newSelectedRowKeys.push(skuId) : remove(newSelectedRowKeys, item => item === skuId)
        setIndeterminate(!!newSelectedRowKeys.length && newSelectedRowKeys.length < dataSource.length)
        setCheckAll(newSelectedRowKeys.length === dataSource.length)
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const save = (record, value, index, dataIndex) => {
        form.setFields([index, dataIndex], value)
        handleSave({ ...record, [dataIndex]: value })
    }

    const handleSave = row => {
        const newData = [...dataSource]
        const index = newData.findIndex(item => row.stock.id === item.stock.id)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setDataSource(newData)
    }

    function handleSubmit() {
        Modal.confirm({
            title: t('warehouse:title.confirm_move_product'),
            cancelText: t('btn.cancel'),
            okText: t('btn.confirm'),
            onOk: handleMove,
        })
    }

    const handleMove = () => {
        form.validateFields().then(() => {
            const dataChecked = dataSource.filter(item => selectedRowKeys.includes(item.stock.id));

            const refactorData = map(dataChecked, item => {
                return {
                    stock_id: item.stock.id,
                    quantity: +item.quantity,
                    warehouse_area_id:
                        map(
                            filter(warehouseAreaList, val => val.code === item.final_location),
                            'id'
                        )[0] || '',
                };
            });

            const data = {
                ...input,
                stocks: sortBy([...refactorData], o=>o.stock_id),
            };

            setDirty(true)
            setLoadingButton(true);
            api.changePositionStocks(data)
                .then(res => {
                    form.resetFields();
                    getProducts();
                    notification.success(t('warehouse:message.change_position_success'));

                    setSelectedRowKeys([])
                    setCheckAll(false)
                    setIndeterminate(false)
                    setDirty(false)
                    if (!isEmpty(get(res, 'data.warnings'))) {
                        const idRes = map(get(res, 'data.warnings'), 'stock_id');

                        const dataRes = filter(dataSource, item => idRes.includes(item.stock.id));

                        const newData = map(dataRes, item => {
                            get(res, 'data.warnings').map(val => {
                                item = {
                                    ...item,
                                    message: val.message,
                                };
                            });
                            return item;
                        });
                        confirm(newData);
                    }
                })
                .catch(err => {
                    const { response } = err
                    const code = get(response, 'data.code')

                    if (code === 'REQUEST_PROCESSED') {
                        setDirty(true)
                        notification.warning(t('message.request_process'))
                    }else{
                        generatorKey()
                        setDirty(false)
                        notification.error(t('warehouse:message.change_position_fail'));
                    }

                })
                .finally(() => {
                    setLoadingButton(false);
                });
        });
    }

    const confirm = data => {
        Modal.confirm({
            title: <p>{t('warehouse:title.product_list_error')}</p>,
            icon: false,
            content: <ModalBody data={data} />,
            width: 700,
            cancelText: t('btn.close'),
            okButtonProps: { hidden: true },
        })
    }

    return (
        <>
            {' '}
            {dirty && <LeavePageBlocker when={dirty} />}
            <Spin spinning={loading}>
                <div className="text-right">
                    <Button
                        onClick={handleSubmit}
                        className="_btn-move-products btn text-right"
                        type="primary"
                        loading={loadingButton}
                        // disabled={!warehouse_id || !warehouse_area_id || isEmpty(selectedRowKeys)}
                    >
                        {t('btn.move')}
                    </Button>
                </div>
                <Form form={form}>
                    <div className={`ant-table-wrapper list-products-table ant-table-scroll-horizontal ${xs && 'ant-table-ping-right'}`}>
                        <div className="ant-table-container">
                            <div
                                className="ant-table-content"
                                style={{ overflow: 'auto hidden' }}
                            >
                                <table
                                    style={{ width: '576px', minWidth: '100%', tableLayout: 'auto' }}
                                    className="_list-products-table mt-3"
                                >
                                    <thead className="ant-table-thead">
                                        <tr>
                                            <th className="ant-table-cell ant-table-selection-column _move-product-selection-all">
                                                <div className="ant-table-selection">
                                                    <Checkbox
                                                        onChange={onCheckAllChange}
                                                        indeterminate={indeterminate}
                                                        checked={checkAll}
                                                        disabled={isEmpty(dataSource)}
                                                    />
                                                </div>
                                            </th>
                                            <th className="ant-table-cell _move-product-column-sku ">{t('product:label.sku')}</th>
                                            <th className="ant-table-cell _move-product-column-sku_stock">{t('warehouse:label.sku_stock')}</th>
                                            <th className="ant-table-cell _move-product-column-quantity">{t('warehouse:label.move_quantity')}</th>
                                            <th className="ant-table-cell _move-product-column-destination_location">
                                                {t('warehouse:label.destination_location')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="ant-table-tbody">
                                        {!isEmpty(dataSource) &&
                                            dataSource.map((data, index) => {
                                                const { quantity, sku, stock, final_location } = data
                                                return (
                                                    <tr
                                                        key={index}
                                                        className="ant-table-row ant-table-row ant-table-row-level-0"
                                                    >
                                                        <td className="ant-table-cell ant-table-selection-column">
                                                            <Checkbox
                                                                disabled={!stock?.id}
                                                                checked={selectedRowKeys.includes(stock?.id)}
                                                                onChange={e => onChangeCheck(stock?.id, e)}
                                                            />
                                                        </td>

                                                        <td className="ant-table-cell _sku-column">
                                                            <div className="d-flex align-items-center">{sku?.code}</div>
                                                        </td>

                                                        <td className="ant-table-cell stock-column">
                                                            <p>{stock?.quantity}</p>
                                                        </td>

                                                        {['quantity', 'final_location'].map((item, idx) => {
                                                            return (
                                                                <td
                                                                    className={`ant-table-cell _${item}-column`}
                                                                    key={idx}
                                                                    style={{
                                                                        margin: 0,
                                                                        width: '450px',
                                                                    }}
                                                                >
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        {selectedRowKeys.includes(stock?.id) ? (
                                                                            <>
                                                                                {item === 'final_location' ? (
                                                                                    <Form.Item
                                                                                        style={{
                                                                                            margin: 0,
                                                                                            width: '100%',
                                                                                        }}
                                                                                        name={[index, item]}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: t('validation:required', {
                                                                                                    attribute: t('warehouse:label.destination_location'),
                                                                                                }),
                                                                                            },
                                                                                            () => ({
                                                                                                validator(_, value) {
                                                                                                    const check = filter(
                                                                                                        warehouseAreaList,
                                                                                                        val =>
                                                                                                            val.code === value && val.id !== warehouse_area_id
                                                                                                    )
                                                                                                    if (value && isEmpty(check)) {
                                                                                                        return Promise.reject(
                                                                                                            new Error(
                                                                                                                t('warehouseArea:message.location_invalid')
                                                                                                            )
                                                                                                        )
                                                                                                    }
                                                                                                    return Promise.resolve()
                                                                                                },
                                                                                            }),
                                                                                        ]}
                                                                                    >
                                                                                        <Input
                                                                                            className="_move-product-by-scan-barcode"
                                                                                            onPressEnter={e => save(data, e.target.value.trim(), index, item)}
                                                                                            placeholder={t(
                                                                                                'warehouse:placeholder.scan_type_destination_location'
                                                                                            )}
                                                                                            onBlur={e => save(data, e.target.value, index, item)}
                                                                                            suffix={
                                                                                                <ScanBarcode
                                                                                                    onUpdate={value => save(data, value.trim(), index, item)}
                                                                                                    // disabled={isScanning}
                                                                                                />
                                                                                            }
                                                                                        />
                                                                                    </Form.Item>
                                                                                ) : (
                                                                                    <Form.Item
                                                                                        style={{
                                                                                            margin: 0,
                                                                                            width: '100%',
                                                                                        }}
                                                                                        initialValue={0}
                                                                                        name={[index, item]}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: t('validation:required', {
                                                                                                    attribute: t('quantity'),
                                                                                                }),
                                                                                            },
                                                                                            {
                                                                                                pattern: /^\+?([0-9]\d*)$/,
                                                                                                message: t('validation:min.numeric', { min: 0 }),
                                                                                            },
                                                                                            () => ({
                                                                                                validator(_, value) {
                                                                                                    if (Number.isNaN(value)) {
                                                                                                        return Promise.reject()
                                                                                                    } else {
                                                                                                        if (
                                                                                                            typeof value === 'string' ||
                                                                                                            value === undefined ||
                                                                                                            Number(value) <= Number(stock.quantity)
                                                                                                        ) {
                                                                                                            return Promise.resolve()
                                                                                                        } else {
                                                                                                            return Promise.reject(
                                                                                                                new Error(
                                                                                                                    t(
                                                                                                                        'warehouse:message.moves_cannot_exceed_the_amount'
                                                                                                                    )
                                                                                                                )
                                                                                                            )
                                                                                                        }
                                                                                                    }
                                                                                                },
                                                                                            }),
                                                                                        ]}
                                                                                    >
                                                                                        <InputNumber
                                                                                            onPressEnter={e => save(data, e.target.value, index, item)}
                                                                                            min={0}
                                                                                            className="w-100 _move-product-quantity"
                                                                                            placeholder={t('order:placeholder.quantity')}
                                                                                            onBlur={e => save(data, e.target.value, index, item)}
                                                                                        />
                                                                                    </Form.Item>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {item === 'final_location' ? (
                                                                                    <Input
                                                                                        placeholder={t('warehouse:placeholder.scan_type_destination_location')}
                                                                                        className="_move-product-by-scan-barcode"
                                                                                        suffix={
                                                                                            <ScanOutlined
                                                                                                style={{
                                                                                                    color: 'rgba(0,0,0,.45)',
                                                                                                }}
                                                                                                className="_scan-order-scan-barcode"
                                                                                            />
                                                                                        }
                                                                                        value={final_location}
                                                                                        disabled
                                                                                    />
                                                                                ) : (
                                                                                    <InputNumber
                                                                                        value={quantity}
                                                                                        disabled
                                                                                        className="w-100 _move-product-quantity"
                                                                                        min={0}
                                                                                        placeholder={t('order:placeholder.quantity')}
                                                                                    />
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Form>
                {isEmpty(dataSource) && (
                    <div className="text-center mt-4 mb-5">
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={t('label.no_data')}
                        />
                    </div>
                )}
                {/* <CustomizedPagination pagination={pagination} addFilter={setParams} filters={params} /> */}
                <div className="text-right mt-4">
                    <Button
                        onClick={handleSubmit}
                        className="_btn-move-products btn text-right"
                        type="primary"
                        loading={loadingButton}
                        disabled={!warehouse_id || !warehouse_area_id || isEmpty(selectedRowKeys)}
                    >
                        {t('btn.move')}
                    </Button>
                </div>
            </Spin>
        </>
    )
}

export default TableCustomize
